/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import classNames from 'classnames';
import styles from './index.module.css';
import { DatePicker } from 'antd';
import { useCallback } from 'react';
import { ReactComponent as CalendarIconSvg } from 'assets/images/icons/calendar.svg';
import dayjs, { Dayjs } from 'dayjs';

export const dateFormatDefault = 'DD/MM/YYYY';

export enum DatePickerSize {
  Small = 'small',
  Middle = 'middle',
  Large = 'large',
}

type Props = {
  minDate?: Date | undefined | null;
  maxDate?: Date | undefined | null;
  placeholder?: string;
  dateFormat?:
    | {
        format: string;
        type?: 'mask';
      }
    | string;
  disabled?: boolean;
  value?: Date | Dayjs | undefined | null;
  onChange?: (date: Date | undefined | null) => void;
  size?: DatePickerSize;
  error?: string;
};

const DateTimePicker = (props: Props) => {
  const handleDateChange = useCallback(
    (date: Dayjs | undefined | null) => {
      props.onChange?.(dayjs(date).toDate() ?? undefined);
    },
    [props]
  );

  return (
    <div className={classNames(styles.picker_container)}>
      <DatePicker
        value={props.value ? dayjs(props.value) : undefined}
        onChange={handleDateChange}
        format={props.dateFormat || dateFormatDefault}
        placeholder={props.placeholder || ''}
        disabled={props.disabled}
        showToday
        showNow={false}
        size={props.size}
        suffixIcon={<CalendarIconSvg />}
        minDate={props.minDate ? dayjs(props.minDate) : undefined}
        maxDate={props.maxDate ? dayjs(props.maxDate) : undefined}
      />
      {props.error && <span className={styles.error}>{props.error}</span>}
    </div>
  );
};

export default DateTimePicker;
