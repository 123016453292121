/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import localJson from './local.json';
import commonJson from './common.json';

function getConfig() {
  switch (process.env.REACT_APP_ENV) {
    // case 'STAGE':
    //   return stageJson;

    // case 'PROD':
    //   return prodJson;

    default:
      return localJson;
  }
}

const currentConfig = getConfig();

export const ORIGIN_URL = currentConfig.originUrl;

export const AUTH_API_URL = `${currentConfig.api.baseUrl}/${currentConfig.api.authApi}`;

export const CURRENCY_API_URL = `${currentConfig.api.baseUrl}/${currentConfig.api.currencyApi}`;

export const QUESTS_API_URL = `https://ad3-api.staging.piefi.app/${currentConfig.api.rewardApi}`;

export const WALLET_API_URL = `${currentConfig.api.baseUrl}/${currentConfig.api.walletApi}`;

export const APPROVER_API_URL = `${currentConfig.api.baseUrl}/${currentConfig.api.approverApi}`;

export const ACQUIRING_API_URL = `${currentConfig.api.baseUrl}/${currentConfig.api.acquiringApi}`;

export const CARDHOLDER_API_URL = `${currentConfig.api.baseUrl}/${currentConfig.api.cardHolderApi}`;

export const AUTH_CLIENT_ID = currentConfig.api.clientId;

export const PARTNER_ID = currentConfig.api.partnerId;

export const LOCAL_STORAGE_KEYS = commonJson.localStorageKeys;

export const URL_SEARCH_PARAMS = commonJson.urlSearchParams;

export const COMPANY_NAME = commonJson.companyName;

export const EURO_SIGN = commonJson.euroSign;

export const SENTRY = commonJson.sentry;
