/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import React, { useState } from 'react';
import Button from 'components/core/button';
import FormListSingleSelect from 'components/form/form-list-single-select';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { SelectItemModel } from 'models/form/SelectItemModel';
import SearchField from 'components/core/search-field';
import { QuestStatus } from 'api/quests';
import { QuestsModel } from 'models/QuestModel';
import { AdminQuestModel } from 'models/request/AdminQuestModel';

const QUEST_STATUSES: QuestStatus[] = [
  QuestStatus.COMPLETED,
  QuestStatus.DELETED,
  QuestStatus.INACTIVE,
  QuestStatus.NEW,
  QuestStatus.STARTED,
];

const selectOptions = QUEST_STATUSES.map((status) => ({
  id: status.toLocaleUpperCase(),
  label: status,
  value: status,
}));

type Props = {
  collection: CollectionWithPages<QuestsModel, AdminQuestModel>;
};

const SidebarQuestsFilter = observer(({ collection }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();
  const { setFilterQueryParams } = useSetQueryParams();

  const defaultStatus = selectOptions.find((x) =>
    [...(collection.filter.status || [])].find((status) => status === x.id)
  );
  const [selectStatus, setSelectStatus] = useState<
    SelectItemModel<string> | undefined
  >(defaultStatus);

  //const [name, setName] = useState<string>(collection.filter.name || '');
  const [name, setName] = useState<string>('');

  const onChangeSelectStatus = (val: SelectItemModel<string> | undefined) => {
    setSelectStatus(val);
  };

  const onChangeName = (val: string | '') => {
    setName(val);
  };

  const onApply = useCallback(() => {
    setFilterQueryParams([
      { id: URL_SEARCH_PARAMS.page, value: '1' },

      {
        id: URL_SEARCH_PARAMS.status,
        value: selectStatus ? selectStatus.id : undefined,
      },
    ]);

    layoutStore.toggleSidebar(false);
  }, [layoutStore, setFilterQueryParams, selectStatus]);

  return (
    <React.Fragment>
      <SearchField
        className={'mb-4'}
        placeholder={intl.formatMessage({
          id: 'sidebar.filters.quest_name',
          defaultMessage: 'Search by Name',
        })}
        value={name}
        onChange={onChangeName}
      />

      <FormListSingleSelect
        title={intl.formatMessage({
          id: 'sidebar.filters.status',
          defaultMessage: 'Status',
        })}
        options={selectOptions}
        selected={selectStatus}
        onSelect={onChangeSelectStatus}
        collapse={true}
      />

      <Button className={styles.apply_btn} variant='primary' onClick={onApply}>
        {intl.formatMessage({
          id: 'sidebar.filters.apply',
          defaultMessage: 'Apply',
        })}
      </Button>
    </React.Fragment>
  );
});

export const useSidebarQuestsFilter = ({ collection }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.filters',
        defaultMessage: 'Filters',
      }),
      <SidebarQuestsFilter collection={collection} />
    );
  }, [collection, intl, layoutStore]);

  return {
    showQuestsFiltersSidebar: showSidebar,
  };
};
