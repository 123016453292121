/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Quest, UserQuestStatus, UserQuest } from 'api/quests';

export class UserQuestsModel {
  constructor(dto: UserQuest) {
    this.id = dto.userId ?? '';
    this.quest = dto.quest;
    this.userId = dto.userId ?? '';
    this.questUrl = dto.questUrl;
    this.status = dto.status;
  }

  quest?: Quest;
  /** @format uuid */
  userId?: string;
  id: string;
  questUrl?: string;
  status?: UserQuestStatus;
}
