/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { observer } from 'mobx-react-lite';
import { useUserDetailsPageStore } from 'modules/user-details/provider/StoreProvider';
import { useCallback, useEffect } from 'react';
import AccountUsersTable from 'components/tables/account-users-table';
import { useNavigate } from 'react-router-dom';
import { AccountUserModel } from 'models/AccountUserModel';
import { ROUTES } from 'routes';
import { TotalBalance } from './total-balance';

const UserDetailsAccountsTab = observer(() => {
  const userDetailsPageStore = useUserDetailsPageStore();

  const { page, filter } = useParseCollectionQueryParams({
    collection: userDetailsPageStore.accounts,
  });

  const navigate = useNavigate();

  const onClick = useCallback(
    (account: AccountUserModel) => {
      navigate(
        ROUTES.userDetails.accountDetails.getFullPath(
          userDetailsPageStore.details?.id || '',
          account.id
        )
      );
    },
    [navigate, userDetailsPageStore.details?.id]
  );

  useEffect(() => {
    userDetailsPageStore.accounts.fetchData(filter, page);
  }, [filter, page, userDetailsPageStore.accounts]);

  return (
    <>
      <AccountUsersTable
        collection={userDetailsPageStore.accounts}
        onRowClick={onClick}
        renderBalanceCell={(accountId) => (
          <TotalBalance accountId={accountId} />
        )}
      />
    </>
  );
});

export default UserDetailsAccountsTab;
