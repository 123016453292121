/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiError {
  key?: string;
  description: string;
}

export interface ApiErrorResponse {
  status:
    | '100 CONTINUE'
    | '101 SWITCHING_PROTOCOLS'
    | '102 PROCESSING'
    | '103 EARLY_HINTS'
    | '103 CHECKPOINT'
    | '200 OK'
    | '201 CREATED'
    | '202 ACCEPTED'
    | '203 NON_AUTHORITATIVE_INFORMATION'
    | '204 NO_CONTENT'
    | '205 RESET_CONTENT'
    | '206 PARTIAL_CONTENT'
    | '207 MULTI_STATUS'
    | '208 ALREADY_REPORTED'
    | '226 IM_USED'
    | '300 MULTIPLE_CHOICES'
    | '301 MOVED_PERMANENTLY'
    | '302 FOUND'
    | '302 MOVED_TEMPORARILY'
    | '303 SEE_OTHER'
    | '304 NOT_MODIFIED'
    | '305 USE_PROXY'
    | '307 TEMPORARY_REDIRECT'
    | '308 PERMANENT_REDIRECT'
    | '400 BAD_REQUEST'
    | '401 UNAUTHORIZED'
    | '402 PAYMENT_REQUIRED'
    | '403 FORBIDDEN'
    | '404 NOT_FOUND'
    | '405 METHOD_NOT_ALLOWED'
    | '406 NOT_ACCEPTABLE'
    | '407 PROXY_AUTHENTICATION_REQUIRED'
    | '408 REQUEST_TIMEOUT'
    | '409 CONFLICT'
    | '410 GONE'
    | '411 LENGTH_REQUIRED'
    | '412 PRECONDITION_FAILED'
    | '413 PAYLOAD_TOO_LARGE'
    | '413 REQUEST_ENTITY_TOO_LARGE'
    | '414 URI_TOO_LONG'
    | '414 REQUEST_URI_TOO_LONG'
    | '415 UNSUPPORTED_MEDIA_TYPE'
    | '416 REQUESTED_RANGE_NOT_SATISFIABLE'
    | '417 EXPECTATION_FAILED'
    | '418 I_AM_A_TEAPOT'
    | '419 INSUFFICIENT_SPACE_ON_RESOURCE'
    | '420 METHOD_FAILURE'
    | '421 DESTINATION_LOCKED'
    | '422 UNPROCESSABLE_ENTITY'
    | '423 LOCKED'
    | '424 FAILED_DEPENDENCY'
    | '425 TOO_EARLY'
    | '426 UPGRADE_REQUIRED'
    | '428 PRECONDITION_REQUIRED'
    | '429 TOO_MANY_REQUESTS'
    | '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
    | '451 UNAVAILABLE_FOR_LEGAL_REASONS'
    | '500 INTERNAL_SERVER_ERROR'
    | '501 NOT_IMPLEMENTED'
    | '502 BAD_GATEWAY'
    | '503 SERVICE_UNAVAILABLE'
    | '504 GATEWAY_TIMEOUT'
    | '505 HTTP_VERSION_NOT_SUPPORTED'
    | '506 VARIANT_ALSO_NEGOTIATES'
    | '507 INSUFFICIENT_STORAGE'
    | '508 LOOP_DETECTED'
    | '509 BANDWIDTH_LIMIT_EXCEEDED'
    | '510 NOT_EXTENDED'
    | '511 NETWORK_AUTHENTICATION_REQUIRED';
  message: string;
  traceId: string;
  errors?: ApiError[];
}

export interface AdminToken {
  /** @format int64 */
  id?: number;
  name: string;
  slug: string;
  currencySlug: string;
  blockchainSlug: string;
  address: string;
  /** @format int32 */
  decimals: number;
  nativeSign: boolean;
  /** @format int32 */
  minConsolidation?: number;
  settings?: TokenSettings[];
}

export interface TokenSettings {
  partner: string;
  group: string;
  depositEnabled: boolean;
  depositFeePercent?: number | null;
  depositFeeFixed?: number | null;
  depositFeeMin?: number | null;
  depositFeeMax?: number | null;
  withdrawEnabled: boolean;
  withdrawFeePercent?: number | null;
  withdrawFeeFixed?: number | null;
  withdrawFeeMin?: number | null;
  withdrawFeeMax?: number | null;
}

export interface AdminCurrency {
  /** @format int64 */
  id?: number;
  slug: string;
  shortName: string;
  description: string;
  type: string;
  /** @format int32 */
  decimals: number;
  iconUrl?: string | null;
  /** @format int32 */
  totalsPrecision?: number | null;
  /** @format int32 */
  roundingPrecision?: number | null;
  instrument?: string | null;
  settings?: CurrencySettings[];
  tokens?: AdminToken[];
}

export interface CurrencySettings {
  enabled: boolean;
  partner: string;
  group: string;
  walletEnabled: boolean;
  transferEnabled: boolean;
  transferFeePercent?: number | null;
  transferFeeFixed?: number | null;
  transferFeeMin?: number | null;
  transferFeeMax?: number | null;
}

export interface AdminExchangePair {
  partner: string;
  group: string;
  fromCurrencySlug: string;
  toCurrencySlug: string;
  /**
   * Spread
   * @format number
   * @example "1.12"
   */
  spread: string;
  fee?: FeeConfig;
}

export interface FeeConfig {
  /**
   * Fee percent
   * @example 0.1
   */
  feePercent?: number;
  /**
   * Fixed fee
   * @example 5
   */
  feeFixed?: number;
  /**
   * Minimum fee
   * @example 1
   */
  feeMin?: number;
  /**
   * Maximum fee
   * @example 10
   */
  feeMax?: number;
}

export interface Blockchain {
  /** @format int64 */
  id?: number;
  name?: string;
  slug?: string;
  explorer?: string;
}

export interface CurrencyShort {
  /** @format int64 */
  id?: number;
  slug?: string;
  shortName?: string;
  description?: string;
  type?: string;
  /** @format int32 */
  decimals?: number;
  iconUrl?: string;
  /** @format int32 */
  totalsPrecision?: number;
  /** @format int32 */
  roundingPrecision?: number;
  /**
   * @format number
   * @example "9.87"
   */
  eurPrice?: string;
  settings?: CurrencySettings;
}

export interface Token {
  /** @format int64 */
  id?: number;
  name?: string;
  slug?: string;
  address?: string;
  /** @format int32 */
  decimals?: number;
  nativeSign?: boolean;
  /** @format int32 */
  minConsolidation?: number;
  currency?: CurrencyShort;
  blockchain?: Blockchain;
  settings?: TokenSettings;
}

export interface TokenShort {
  /** @format int64 */
  id?: number;
  name?: string;
  slug?: string;
  address?: string;
  /** @format int32 */
  decimals?: number;
  nativeSign?: boolean;
  /** @format int32 */
  minConsolidation?: number;
}

export interface RateRequest {
  fromCurrency: string;
  toCurrency: string;
  /**
   * Amount
   * @format number
   * @example "1.12"
   */
  amount?: string;
  account?: string;
  partner?: string;
}

export interface SignedRate {
  rateRequest?: RateRequest;
  /**
   * Rate for exchange
   * @format number
   * @example "1.12"
   */
  rate?: string;
  feeConfig?: FeeConfig;
  /** Valid until date in ISO 8601 format */
  validUntil?: string;
  signature?: string;
}

export interface PriceDto {
  baseCurrencySlug?: string;
  quoteCurrencySlug?: string;
  price?: number;
}

export interface Currency {
  /** @format int64 */
  id?: number;
  slug?: string;
  shortName?: string;
  description?: string;
  type?: string;
  /** @format int32 */
  decimals?: number;
  iconUrl?: string;
  /** @format int32 */
  totalsPrecision?: number;
  /** @format int32 */
  roundingPrecision?: number;
  /**
   * @format number
   * @example "9.87"
   */
  eurPrice?: string;
  settings?: CurrencySettings;
  /** @uniqueItems true */
  tokens?: TokenWithDescription[];
}

export interface TokenWithDescription {
  /** @format int64 */
  id?: number;
  name?: string;
  slug?: string;
  address?: string;
  /** @format int32 */
  decimals?: number;
  nativeSign?: boolean;
  /** @format int32 */
  minConsolidation?: number;
  blockchain?: Blockchain;
  settings?: TokenSettings;
}

export interface ExchangePairConfigurationDto {
  currencies?: AdminCurrency[];
  exchangePairs?: AdminExchangePair[];
  invalidExchangePairs?: AdminExchangePair[];
  partner?: string;
  group?: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://api.stg.darknet.piefi.app/currency',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Currency
 * @version 0.0.1-9583b86c
 * @baseUrl https://api.stg.darknet.piefi.app/currency
 *
 * API for getting currencies, tokens, blockchains and settings.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  admin = {
    /**
     * @description Update token, or create new one if not exist. Must past complete token object. All fields will be overridden.
     *
     * @tags Admin
     * @name AdminUpdateToken
     * @summary Update token
     * @request PUT:/admin/token/{tokenId}
     * @secure
     */
    adminUpdateToken: (tokenId: number, data: AdminToken, params: RequestParams = {}) =>
      this.request<AdminToken, ApiErrorResponse>({
        path: `/admin/token/${tokenId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminDeleteToken
     * @summary Delete token settings
     * @request DELETE:/admin/token/{tokenId}
     * @secure
     */
    adminDeleteToken: (tokenId: number, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/token/${tokenId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Update token settings, or create new one if not exist. Must past complete token settings object. All fields will be overridden.
     *
     * @tags Admin
     * @name AdminUpdateTokenSettings
     * @summary Update token settings
     * @request PUT:/admin/token/{tokenId}/settings/{partner}/{group}
     * @secure
     */
    adminUpdateTokenSettings: (
      tokenId: number,
      partner: string,
      group: string,
      data: TokenSettings,
      params: RequestParams = {}
    ) =>
      this.request<TokenSettings, ApiErrorResponse>({
        path: `/admin/token/${tokenId}/settings/${partner}/${group}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminDeleteTokenSettings
     * @summary Delete token settings
     * @request DELETE:/admin/token/{tokenId}/settings/{partner}/{group}
     * @secure
     */
    adminDeleteTokenSettings: (tokenId: number, partner: string, group: string, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/token/${tokenId}/settings/${partner}/${group}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Update currency, or create new one if not exist. Must past complete currency object.  All fields will be overridden.
     *
     * @tags Admin
     * @name AdminUpdateCurrency
     * @summary Update currency
     * @request PUT:/admin/currency/{slug}
     * @secure
     */
    adminUpdateCurrency: (slug: string, data: AdminCurrency, params: RequestParams = {}) =>
      this.request<AdminCurrency, ApiErrorResponse>({
        path: `/admin/currency/${slug}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminDeleteCurrency
     * @summary Delete currency
     * @request DELETE:/admin/currency/{slug}
     * @secure
     */
    adminDeleteCurrency: (slug: string, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/currency/${slug}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Update currency settings, or create new one if not exist. Must past complete currency settings object. All fields will be overridden.
     *
     * @tags Admin
     * @name AdminUpdateCurrencySettings
     * @summary Update currency settings
     * @request PUT:/admin/currency/{slug}/settings/{partner}/{group}
     * @secure
     */
    adminUpdateCurrencySettings: (
      slug: string,
      partner: string,
      group: string,
      data: CurrencySettings,
      params: RequestParams = {}
    ) =>
      this.request<CurrencySettings, ApiErrorResponse>({
        path: `/admin/currency/${slug}/settings/${partner}/${group}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminDeleteCurrencySettings
     * @summary Delete currency settings
     * @request DELETE:/admin/currency/{slug}/settings/{partner}/{group}
     * @secure
     */
    adminDeleteCurrencySettings: (slug: string, partner: string, group: string, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/currency/${slug}/settings/${partner}/${group}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description List all exchange pairs settings (must be matrix of all possible exchange pairs). Settings are partner and group related.
     *
     * @tags Admin
     * @name AdminGetAllExchangePairConfigs
     * @summary List all exchange pairs.
     * @request GET:/admin/exchange_pair
     * @secure
     */
    adminGetAllExchangePairConfigs: (
      query: {
        partner: string;
        group: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ExchangePairConfigurationDto, ApiErrorResponse>({
        path: `/admin/exchange_pair`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update exchange pair settings, or create new one if not exist. Must past complete exchange pair settings object. All fields will be overridden.
     *
     * @tags Admin
     * @name AdminUpdateExchangePairSettings
     * @summary Update exchange pair
     * @request POST:/admin/exchange_pair
     * @secure
     */
    adminUpdateExchangePairSettings: (data: AdminExchangePair, params: RequestParams = {}) =>
      this.request<AdminExchangePair, ApiErrorResponse>({
        path: `/admin/exchange_pair`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminCreateBlockchain
     * @request POST:/admin/blockchain
     */
    adminCreateBlockchain: (data: Blockchain, params: RequestParams = {}) =>
      this.request<Blockchain, ApiErrorResponse>({
        path: `/admin/blockchain`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminListAllToken
     * @summary List all tokens
     * @request GET:/admin/token
     * @secure
     */
    adminListAllToken: (
      query?: {
        partner?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminToken[], ApiErrorResponse>({
        path: `/admin/token`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminListAllCurrency
     * @summary List all currencies
     * @request GET:/admin/currency
     * @secure
     */
    adminListAllCurrency: (
      query?: {
        partner?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminCurrency[], ApiErrorResponse>({
        path: `/admin/currency`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminDeleteExchangePair
     * @summary Delete exchange pair settings
     * @request DELETE:/admin/exchange_pair/{exchangePairId}
     * @secure
     */
    adminDeleteExchangePair: (exchangePairId: number, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/exchange_pair/${exchangePairId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  preferencesController = {
    /**
     * @description Returns ordered list of currency slugs
     *
     * @tags preferences-controller
     * @name GetPreferredCurrencies
     * @summary Get preferred currencies for account
     * @request GET:/preferred/{accountId}
     * @secure
     */
    getPreferredCurrencies: (accountId: string, params: RequestParams = {}) =>
      this.request<string[], ApiErrorResponse>({
        path: `/preferred/${accountId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Sets new list of preferred currencies, returns ordered list of currency slugs
     *
     * @tags preferences-controller
     * @name SetPreferredCurrenciesSlug
     * @summary Sets new preferred currencies
     * @request POST:/preferred/{accountId}
     * @secure
     */
    setPreferredCurrenciesSlug: (accountId: string, data: string[], params: RequestParams = {}) =>
      this.request<string[], ApiErrorResponse>({
        path: `/preferred/${accountId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  tokens = {
    /**
     * No description
     *
     * @tags Tokens
     * @name GetAllTokens
     * @request GET:/token
     */
    getAllTokens: (
      query?: {
        currency?: string;
        blockchain?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Token[], ApiErrorResponse>({
        path: `/token`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tokens
     * @name GetAllTokensShort
     * @request GET:/token/short
     */
    getAllTokensShort: (params: RequestParams = {}) =>
      this.request<TokenShort[], ApiErrorResponse>({
        path: `/token/short`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  rates = {
    /**
     * @description Get rate for exchange pair and amount. Rate is singed and available only specific time. To receive special rate pass to method account or partner code.
     *
     * @tags Rates
     * @name GetRate
     * @summary Get rate for exchange pair
     * @request GET:/rate
     */
    getRate: (
      query: {
        fromCurrency: string;
        toCurrency: string;
        /** @format number */
        amount: string;
        account?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SignedRate, ApiErrorResponse>({
        path: `/rate`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  rateInstruments = {
    /**
     * No description
     *
     * @tags Rate instruments
     * @name GetInstrumentList
     * @request GET:/instruments/
     */
    getInstrumentList: (params: RequestParams = {}) =>
      this.request<PriceDto[], ApiErrorResponse>({
        path: `/instruments/`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  currencies = {
    /**
     * No description
     *
     * @tags Currencies
     * @name GetAllCurrencies
     * @request GET:/currency
     */
    getAllCurrencies: (params: RequestParams = {}) =>
      this.request<Currency[], ApiErrorResponse>({
        path: `/currency`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Currencies
     * @name GetCurrencyBySlug
     * @request GET:/currency/slug/{slug}
     */
    getCurrencyBySlug: (slug: string, params: RequestParams = {}) =>
      this.request<Currency, ApiErrorResponse>({
        path: `/currency/slug/${slug}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Currencies
     * @name GetAllCurrenciesShort
     * @request GET:/currency/short
     */
    getAllCurrenciesShort: (params: RequestParams = {}) =>
      this.request<CurrencyShort[], ApiErrorResponse>({
        path: `/currency/short`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Currencies
     * @name GetCurrencyByName
     * @request GET:/currency/name/{name}
     */
    getCurrencyByName: (name: string, params: RequestParams = {}) =>
      this.request<Currency, ApiErrorResponse>({
        path: `/currency/name/${name}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  blockchains = {
    /**
     * No description
     *
     * @tags Blockchains
     * @name GetAllBlockchains
     * @request GET:/blockchain
     */
    getAllBlockchains: (params: RequestParams = {}) =>
      this.request<Blockchain[], ApiErrorResponse>({
        path: `/blockchain`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
}
