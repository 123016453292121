import type { FC } from 'react';
import { useCallback } from 'react';

import { Event } from '../Event';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CreateQuestForm } from '..';
import styles from './index.module.css';
import Button from 'components/core/button';
import { useIntl } from 'react-intl';
import { FaPlus } from 'react-icons/fa6';
export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

export const Events: FC = () => {
  const intl = useIntl();
  const { control } = useFormContext<CreateQuestForm>();

  const { move, fields, remove, update, append } =
    useFieldArray<CreateQuestForm>({
      name: 'events',
      control,
    });

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      move(dragIndex, hoverIndex);
    },
    [move]
  );

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.wrapper}>
          <h4>Events</h4>
          <span className={styles.description}>In order of execution</span>
        </div>
        <Button
          className={styles.button}
          size='medium'
          variant='primary'
          onClick={() => append({ name: 'Event Name' })}
        >
          {intl.formatMessage({
            id: 'sidebar.create_event.add_event',
            defaultMessage: 'Add event',
          })}
          <FaPlus />
        </Button>
      </div>
      <div className={styles.list}>
        {fields.map((event, i) => (
          <Event
            key={event.id}
            index={i}
            onChange={(value) => update(i, { ...event, name: value })}
            id={event.id}
            text={event.name as string}
            moveCard={moveCard}
            onDelete={() => remove(i)}
          />
        ))}
      </div>
    </div>
  );
};
