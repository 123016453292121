/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { TableProps } from 'antd';
import Table from '../core/table';
import { QuestsModel } from 'models/QuestModel';
import { formattedDate, FULL_DATE } from 'utils/dates';
import { BadgeStatus } from 'entities/quest';

type Props = {
  collection: CollectionWithPages<QuestsModel, {}>;
  onQuestClick?: (loan: QuestsModel) => void;
};

const SubscriptionsTable = observer(({ collection, onQuestClick }: Props) => {
  const intl = useIntl();

  const columns: TableProps<QuestsModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.quests.col.name',
        defaultMessage: 'Name',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (name) => <span>{name || '-'}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.reward',
        defaultMessage: 'Reward',
      }),
      dataIndex: 'reward',
      key: 'reward',
      render: (reward) => <span>${reward}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.budget',
        defaultMessage: 'Budget',
      }),
      dataIndex: 'budget',
      key: 'budget',
      render: (budget) => <span>${budget}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.start',
        defaultMessage: 'Start',
      }),
      dataIndex: 'startedAt',
      key: 'startedAt',
      render: (startedAt) => <span>{formattedDate(startedAt, FULL_DATE)}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.finish',
        defaultMessage: 'Finish',
      }),
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      render: (expiredAt) => <span>{formattedDate(expiredAt, FULL_DATE)}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.enrolled',
        defaultMessage: 'Enrolled users',
      }),
      dataIndex: 'enrolledUsers',
      key: 'enrolledUsers',
      render: (enrolledUsers) => <span>{enrolledUsers}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.active_users',
        defaultMessage: 'Active users',
      }),
      dataIndex: 'activeUsers',
      key: 'activeUsers',
      render: (activeUsers) => <span>{activeUsers}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.completed',
        defaultMessage: 'Completed users',
      }),
      dataIndex: 'completedUsers',
      key: 'completedUsers',
      render: (completedUsers) => <span>{completedUsers}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.subscriptions.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <BadgeStatus status={status} />,
      width: 30,
    },
  ];

  return (
    <Table<QuestsModel, {}>
      collection={collection}
      columns={columns}
      onRowClick={onQuestClick}
    />
  );
});

export default SubscriptionsTable;
