import type { Identifier, XYCoord } from 'dnd-core';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ReactComponent as DragIcon } from 'assets/images/icons/drag.svg';
import styles from './index.module.css';
import Button from 'components/core/button';
import { useIntl } from 'react-intl';

export type EventProps = {
  id: any;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDelete: () => void;
  onChange: (value: string) => void;
};

type DragItem = {
  index: number;
  id: string;
  type: string;
};

export const Event: FC<EventProps> = ({
  id,
  text,
  index,
  moveCard,
  onDelete,
  onChange,
}) => {
  const [eventName, setEventName] = useState(text);
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'event',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'event',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [isEdit, setIsEdit] = useState(false);

  const opacity = isDragging ? 0 : 1;
  preview(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={styles.container}
    >
      <div className={styles.wrapper}>
        <button ref={drag}>
          <DragIcon />
        </button>

        <input
          readOnly={!isEdit}
          ref={inputRef}
          onBlur={() => setIsEdit(false)}
          value={eventName}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setIsEdit(false);
              onChange(eventName || text);
              setEventName(eventName || text);
            }
          }}
          className={styles.input}
          onChange={(e) => setEventName(e.target.value)}
        />
      </div>
      <div className={styles.buttons}>
        {!isEdit && (
          <Button
            size='small'
            variant='black'
            onClick={() => {
              setIsEdit(true);
              inputRef.current?.focus();
            }}
          >
            {intl.formatMessage({
              id: 'sidebar.create_event.edit',
              defaultMessage: 'Edit',
            })}
          </Button>
        )}
        <Button size='small' variant='secondary' onClick={onDelete}>
          {intl.formatMessage({
            id: 'sidebar.create_event.delete',
            defaultMessage: 'Delete',
          })}
        </Button>
      </div>
    </div>
  );
};
