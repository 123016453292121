/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Quest, QuestStatus, QuestEvent } from 'api/quests';

export class QuestsModel {
  constructor(dto: Quest) {
    this.id = dto.id ?? '';
    this.accountId = dto.accountId ?? '';
    this.budget = dto.budget;
    this.reward = dto.reward;
    this.currency = dto.currency;
    this.name = dto.name;
    this.delay = dto.delay;
    this.status = dto.status;
    this.budgetBalance = dto.budgetBalance;
    this.events = dto.events;
    this.startedAt = dto.startedAt;
    this.expiredAt = dto.expiredAt;
    this.templateUrl = dto.templateUrl;
    this.completedUsers = dto.completedUsers;
    this.activeUsers = dto.activeUsers;
    this.enrolledUsers = dto.enrolledUsers;
    this.requirement = dto.requirement;
  }

  id: string;
  accountId?: string;
  budget?: number;
  budgetBalance?: number;
  name?: string;
  currency?: string;
  reward?: number;
  enrolledUsers?: number;
  completedUsers?: number;
  activeUsers?: number;
  status?: QuestStatus;
  delay?: number;
  templateUrl?: string;
  expiredAt?: string;
  startedAt?: string;
  events?: QuestEvent[];
  requirement?: object;
}
