import { TableProps } from 'antd';
import CopyButton from 'components/core/copy-button';
import Table from 'components/core/table';
import React from 'react';
import { useIntl } from 'react-intl';
import { cutId } from 'utils/strings';
import styles from './index.module.css';
import { QuestEvent } from 'api/quests';
import TableSimple from 'components/core/table-simple';

type EventsProps = {
  events: QuestEvent[];
};

export const Events = ({ events }: EventsProps) => {
  const intl = useIntl();

  const columns: TableProps<any>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.quests.col.id',
        defaultMessage: 'ID',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id) =>
        id ? (
          <div className='flex items-center gap-2'>
            <span>{cutId(id)}</span>
            <CopyButton text={id} />
          </div>
        ) : (
          '-'
        ),
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.event_name',
        defaultMessage: 'Event name',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (name) => <span>{name}</span>,
      width: 20,
    },
    // {
    //   title: intl.formatMessage({
    //     id: 'table.quests.col.event_completed',
    //     defaultMessage: 'Event completed',
    //   }),
    //   dataIndex: 'name',
    //   key: 'name',
    //   render: (name) => <span>456 (95%)</span>,
    //   width: 20,
    // },
    // {
    //   title: intl.formatMessage({
    //     id: 'table.quests.col.start',
    //     defaultMessage: 'Start',
    //   }),
    //   dataIndex: 'startedAt',
    //   key: 'startedAt',
    //   render: (name) => <span>2h 56m</span>,
    //   width: 20,
    // },
    // {
    //   title: intl.formatMessage({
    //     id: 'table.quests.col.created',
    //     defaultMessage: 'Created',
    //   }),
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (name) => <span>04/23/2024</span>,
    //   width: 20,
    // },
  ];

  return (
    <div className={styles.main}>
      <h3>
        {intl.formatMessage({
          id: 'page.quest.events',
          defaultMessage: 'Events',
        })}
      </h3>
      <TableSimple collection={events} columns={columns} size='large' />
    </div>
  );
};
